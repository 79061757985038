@use 'styles/includes' as *;

.ChartBlock {
    $root: &;
    margin: 2rem auto;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &__Container {
        @extend %container;
    }

    &__Title {
        margin: 0 0 2rem;
        font-family: $font-family-bold;
        font-size: 1.2rem;
        font-weight: $font-weight-bold;
        line-height: 90%;
        letter-spacing: -0.036rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        position: relative;
        z-index: 1;

        @include media(m) {
            font-size: 2rem;
            letter-spacing: -0.06rem;
        }
    }

    &__Text {
        color: $grey-70;
    }

    &__List {
        display: grid;
        position: relative;
    }

    &__Item {
        position: relative;
        &:not(:first-of-type) {
            padding-top: 2rem;
        }
        &::after {
            content: '';
            width: 100%;
            height: 0.2rem;
            background-color: $grey-40;
            position: absolute;
            bottom: 0;
            left: 0;
            transition:
                background-color 0.3s ease,
                transform 0.3 ease;
            z-index: 10;
        }

        @media (hover: hover) {
            &:hover {
                &::after {
                    z-index: 1;
                    transform: scaleY(2);
                    background-color: $black;
                }
            }
        }

        &--InActive {
            &::after {
                background-color: $black;
                opacity: 0.3;
                z-index: -999;
            }
        }
    }

    &__Image {
        margin: auto;
        aspect-ratio: 3/4;
        max-width: 30rem;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;

        display: none;
        transition: visibility 0.2s linear;
        pointer-events: none;

        &::before,
        &::after {
            content: '';
            background-color: $white;
            mix-blend-mode: difference;
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            pointer-events: none;
        }

        &::before {
            z-index: 1;
        }

        &::after {
            z-index: -1;
        }

        @include media(m) {
            display: block;
        }

        &--Visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &__Blend {
        width: 100%;
        height: 100%;
        position: relative;
        mix-blend-mode: difference;
    }

    &__Link {
        padding: 1rem 0;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 3rem;
        line-height: 100%;
        letter-spacing: -0.12rem;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

        transition: background-color 0.3s ease;
        position: relative;
        overflow: hidden;

        @include media(m) {
            font-size: 8rem;
            letter-spacing: -0.24rem;
        }

        #{$root}--Small & {
            font-size: 2.3rem;
            letter-spacing: -0.092rem;

            @include media(m) {
                font-size: 4.2rem;
                letter-spacing: -0.126rem;
            }

            @include media(xxl) {
                font-size: 6.8rem;
                letter-spacing: -0.204rem;
            }
        }
    }

    &__LinkTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        position: relative;
        color: $black;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;

        opacity: 1;

        @include media(m) {
            text-decoration: none;
            transform: translateX(-4.2rem);
            // transition:
            //     transform $transition-linear,
            //     opacity $transition-linear;

            @media (hover: hover) {
                #{$root}__Item:hover & {
                    transform: translateX(1rem);
                }
            }
        }

        #{$root}__Item--InActive & {
            opacity: 0.3;
            z-index: -99;
        }
    }

    &__Value {
        color: $purple;
        position: relative;
        z-index: 10;
        opacity: 1;
        // transition: opacity $transition-linear;

        #{$root}__Item--InActive & {
            z-index: -99;
            color: $black;
            opacity: 0.3;
        }
    }

    &__Icon {
        display: none;

        @include media(m) {
            display: inline-block;
        }
    }

    &__Button {
        margin: 2rem 0 0;
        text-align: center;
    }
}
